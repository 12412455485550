import React from "react"
import { graphql } from "gatsby"
import BlockContentPrimary from "../components/block content/blockContentPrimary"
import ButtonLink from "../components/ButtonLink"
import IndexContainer from "../components/indexContainer"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import StandardContainer from "../components/containers/StandardContainer"
import Files from "../components/files/Files"

const JobPost = ({ data }) => {
  const { job } = data
  return (
    <Layout>
      <SEO title={job.jobTitle} />
      <StandardContainer className="p-10 relative text-primary">
        <div className="grid gap-y-5">
          <h1 className="text-center ">{job.jobTitle}</h1>
          <h2 className="text-center">{job.subTitle}</h2>
          <p className="italic text-center">
            Deadline: {job.applicationDeadline || "Open until filled"}
          </p>
          <BlockContentPrimary blockData={job.details} />
          {job?.files?.length > 0 && (
            <div>
              <Files files={job.files} />
            </div>
          )}

          <ButtonLink
            to="/opportunities#open"
            text="All jobs"
            className=" justify-self-center uppercase tracking-wide"
          />
        </div>
      </StandardContainer>
    </Layout>
  )
}

export const query = graphql`
  query JobPageQuery($slug: String!) {
    job: sanityJobOpening(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      jobTitle
      subTitle
      details: _rawDetails
      applicationDeadline
      files {
        asset {
          id
          originalFilename
          url
        }
      }
    }
  }
`

export default JobPost
