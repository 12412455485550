import React from "react"

const Files = ({ files }) => {
  return (
    <div>
      <h2 className="mb-3">Files & Downloads</h2>
      <ul className="break-all">
        {files.map(({ asset }) => {
          const { url, originalFilename, id } = asset

          return (
            <li key={`customFile-${id}`} className="blockContent__link">
              <a
                href={`${url}?dl=`}
                title={`Download '${originalFilename}'`}
                className="underline hover:text-blue-400"
                download
              >
                {originalFilename}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Files
